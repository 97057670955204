<template>
    <div class="content-wrapper">
        <order-summary :hiddenActions="hiddenActions" collapsible />
        <FileScanDocumentsList></FileScanDocumentsList>
    </div>
</template>
<script>
    import OrderSummary from "@file-shared/components/OrderSummary";
    import FileScanDocumentsList from "./views/FileScanDocumentsList";
    import { Actions as HeaderActions } from "@/router/actions/document-mgmt";
    import { mapState } from "vuex";
    export default {
        name: "DocumentMgmtMain",
        components: { OrderSummary, FileScanDocumentsList },

        data() {
            return {
                hiddenActions: []
            };
        },

        computed: {
            ...mapState({
                order: state => state.orders.order
            }),
            r2cStatus() {
                if(!this.order.ready2CloseEnabled) return 0;
                if(!this.order.isReady2Close) return 1;
                else return 2;
            }
        },

        created() {
            const self = this;
            let hiddenActions = [];
            switch(self.r2cStatus) {
                case 0: // r2c not enabled
                    hiddenActions = [
                        HeaderActions.READY2CLOSE.key,
                        HeaderActions.PUBLISH.key
                    ];
                    break;
                case 1: // order not published
                    hiddenActions = [ HeaderActions.READY2CLOSE.key ];
                    break;
                case 2: // order published
                    hiddenActions = [ HeaderActions.PUBLISH.key ];
                    break;
            }
            self.hiddenActions = hiddenActions;
        },

        watch:{
            "r2cStatus": {
                immediate: true,
                handler(newValue, oldValue) {
                    if (_.isNil(oldValue)) return;
                    let hiddenActions = [];
                    switch(newValue) {
                        case 0: // r2c not enabled
                            hiddenActions = [
                                HeaderActions.READY2CLOSE.key,
                                HeaderActions.PUBLISH.key
                            ];
                            break;
                        case 1: // order not published
                            hiddenActions = [ HeaderActions.READY2CLOSE.key ];
                            break;
                        case 2: // order published
                            hiddenActions = [ HeaderActions.PUBLISH.key ];
                            break;
                    }
                    this.hiddenActions = hiddenActions;
                },
            },
        },
    }
</script>