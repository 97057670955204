<template>
    <div class="rq-container content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="dg_docmgmt_select_document"
            :title="title"
            :dataSource="items"
            :config="gridConfig"
            :single-selection-enabled="singleSelection"
            @rowDoubleClick="onRowDoubleClick"
            @selectionChanged="onSelectionChanged"
            integrated-search
            hide-export
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";

    import { FileScanDocumentDto }  from "../models";
    import { DocumentFileType, FileScanDocumentType }  from "@documents/enums";

    export default {

        name: "DocumentQueue",

        mixins: [ GridInvokerMixin({ grid:"dataGrid" }) ],

        props: {
            title: { type: String, default: "Select a Document" },
            documents: { type: Array, default: () => [] },
            singleSelection: { type: Boolean, default: false },
        },

        data() {
            return {
                items: [],
                selectedKeys: [],
                selecttedData: []
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "orderRegionId"
            ]),
            selectedId() { return this.selectedKeys?.[0]; },
            selectedItem() { return this.selectedData?.[0]; }
        },

        created() {
            this.initStaticVariables();
            this.initGrid();
            this.fetchData(true);
        },

        methods: {

            initStaticVariables() {
                const self = this;
                self.documentFileTypes = DocumentFileType.lookupItems;
                self.fileScanDocumentTypes = FileScanDocumentType.lookupItems;
                self.fileScanCategories = self.lookupHelpers.getLookupItems(self.lookupItems.FILE_SCAN_CATEGORIES, self.orderRegionId);
                self.documentTags = self.lookupHelpers.getLookupItems(self.lookupItems.DOCUMENT_TAGS);
            },

            initGrid() {
                const self = this;
                self.gridConfig = {
                    keyExpr: "fileScanDocumentID",
                    columns: [
                        {
                            dataField: "description",
                            caption: "Description"
                        },
                        {
                            dataField: "fileScanCategoryID",
                            caption: "Category",
                            minWidth: 100,
                            lookup: {
                                dataSource: self.fileScanCategories,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            visible: false
                        },
                        {
                            dataField: "dateModified",
                            dataType: "datetime",
                            caption: "Date Modified",
                            minWidth: 135,
                            allowSearch: false,
                        },
                        {
                            dataField: "fileType",
                            caption: "Type",
                            lookup: {
                                dataSource: self.documentFileTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "publishedByDisplay",
                            caption: "Author",
                            visible: false
                        }
                    ],
                    noDataText: "No documents have been uploaded for this file."
                };
            },

            fetchData(initial=false) {
                const self = this;

                const processResult = result => {
                    self.items = _.map(result, item => new FileScanDocumentDto(item));
                    self.refresh();
                    return self.items;
                };

                if(initial && !_.isEmpty(self.documents)) {
                    return Promise.resolve(self.documents)
                        .then(processResult);
                }

                let apiPromise = self.$api.FileScanApi.getFileScanDocuments(self.orderId);
                return self.$rqBusy.wait(apiPromise)
                    .then(processResult)
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Error loading uploaded documents.");
                    });
            },

            onRowDoubleClick (e) {
                this.$emit("action", { name: "double-click", data: { key: _.parseNumber(e.key, 0), data: _.get(e, "data", {}) } });
            },

            onSelectionChanged (e) {
                let selectedKeys = _.get(e, "selectedRowKeys", null) || [];
                let selectedItems = _.get(e, "selectedRowsData", null) || [];

                this.selectedData = selectedItems;
                this.selectedKeys = selectedKeys;

                this.$emit("selection-changed", { selectedKeys, selectedItems });
            },

            refresh() {
                this.invokeGridMethod("refresh");
            },

            updateDimensions: _.debounce(function() {
                this.invokeGridMethod("updateDimensions");
            }, 500),
        }
    };
</script>